import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MessageLevel, ValidatorFunctions } from '@unifii/library/common';
import { patchAssets } from '@unifii/markdown-it-assets';
import { CompoundType, Dictionary, Field, FieldType, PageField } from '@unifii/sdk';
import MarkdownIt from 'markdown-it';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

import { BuilderField, UcDefinition, UcField, UcPage, UcProject, UcView } from 'client';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { FieldReference, FieldReferenceHelper, IdentifierFunctions } from 'helpers/helpers';
import { ContextService } from 'services/context.service';
import { MementoService } from 'services/memento.service';

export interface BuilderEventInfo {
    subject?: any;
    source?: string;
    atomic?: boolean;
    errors?: { message: string }[];
    data?: any;
}

export interface BuilderNotificationInfo {
    title?: string;
    message: string;
    level: MessageLevel;
}

export interface BuilderStatus {
    submitted?: boolean;
}

export interface HorizontalTableConfig {
    hideFromColumnsOnMobile: Field[];
    hideFromColumnsOnDesktop: Field[];
}

/** Used as an event bus between Builder components */
@Injectable()
export class BuilderService implements OnDestroy {

    markdown: MarkdownIt;
    builder: BuilderBasic;
    definition: any;
    compound: any;
    childrenProperty: string;
    collections: any[]; // cache
    fieldTemplates: BuilderField[]; // cache
    tags: string[];
    statuses: Set<string>;
    /**
     * TODO temporary fix remove when possible, repeating groups with tables required a list identifiers with
     * configuration so they can hide and show based on screen size. Unfortunately this cant be managed by the
     * field display component as the children identifiers may change, this map references the fields and maps to the identifier
     * when saved
     */
    horizontalTableConfig = new Map<Field, HorizontalTableConfig>();

    // Events notifiers
    ready = new ReplaySubject<void>();
    fieldReady = new Subject<BuilderEventInfo>();
    fieldSelect = new Subject<BuilderEventInfo | null>();
    fieldSelected = new Subject<BuilderEventInfo>();
    fieldAdd = new Subject<BuilderEventInfo>();
    fieldAdded = new Subject<BuilderEventInfo>();
    fieldMove = new Subject<BuilderEventInfo>();
    fieldMoved = new Subject<BuilderEventInfo>();
    fieldRemove = new Subject<BuilderEventInfo>();
    fieldRemoved = new Subject<BuilderEventInfo>();
    fieldEdit = new Subject<BuilderEventInfo>();
    fieldEdited = new Subject<BuilderEventInfo>();
    fieldRefreshed = new Subject<BuilderEventInfo>();
    fieldTemplatesChange = new Subject<null>();
    fieldTemplatesChanged = new ReplaySubject<null>();
    notify = new Subject<BuilderNotificationInfo>();
    expand = new Subject<BuilderEventInfo>();
    tagEdited = new Subject<BuilderEventInfo>();
    busy = new Subject<boolean>();
    cleanDefinitionStart = new Subject<UcDefinition>();
    // submitted (notify postponed subscriber)
    submit = new Subject();

    private errors = new Map<Field, Dictionary<string[]>>();
    private _submitted = new ReplaySubject<boolean>(1);
    private subscriptions = new Subscription();
    private _identifierLimit: number;

    constructor(
        public memento: MementoService,
        public context: ContextService,
        private ucProject: UcProject,
    ) {
        this.markdown = MarkdownIt('commonmark');
        patchAssets(this.markdown);
    }

    reset() {
        this.errors.clear();
        this.tags = [];
        this.statuses = new Set();
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
    }

    init(builder: BuilderBasic, definition: any = null, compound: any = null) {

        this.reset();

        let loadCollections = false;

        this.builder = builder;
        this.definition = definition;
        this.compound = compound;
        this.memento.reset();
        this.errors.clear();

        if (!this.collections) {
            switch (this.builder.type) {
                case CompoundType.Structure:
                    this.childrenProperty = 'children';
                    break;
                case CompoundType.View:
                case CompoundType.Page:
                case CompoundType.Collection:
                    this.childrenProperty = 'fields';
                    loadCollections = true;
                    break;
                case CompoundType.Form:
                    this.childrenProperty = 'fields';
                    break;
            }
        }

        // Load existing tags
        this.refreshTags();

        // Load existing
        this.refreshStatuses();

        // React to submit (save action) and set to submitted
        this.subscriptions.add(this.submit.subscribe(() => {
            this._submitted.next(true);
        }));

        const loadPromises: Promise<any[]>[] = [];

        if (loadCollections) {
            loadPromises.push(this.ucProject.getCollections());
        }

        if (!loadPromises.length) {
            this.ready.next();

            return;
        }

        const promises = Promise.all(loadPromises);

        promises.then((data) => {
            if (loadCollections) {
                this.collections = data[0] ?? [];
            }
            this.ready.next();
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /** Notify to the Builder components that action of submit happened */
    get submitted(): Observable<boolean> {
        return this._submitted;
    }

    get identifierLimit() {
        if (this._identifierLimit) {
            return this._identifierLimit;
        }

        this._identifierLimit = IdentifierFunctions.IDENTIFIER_MAX_LENGTH;

        for (const field of this.fieldIterable(this.definition.fields || [])) {
            if (field.identifier && field.identifier.length > IdentifierFunctions.IDENTIFIER_MAX_LENGTH) {
                this._identifierLimit = IdentifierFunctions.IDENTIFIER_MAX_LENGTH_OLD_LIMIT;
            }
        }

        return this._identifierLimit;
    }

    /** Retrieve a FieldReference definition based on it's type and the current builder */
    getFieldRef(field?: any): FieldReference {
        return FieldReferenceHelper.getFieldReference(field, this.builder.type);
    }

    /** Update the list of errors of a specific field */
    setErrors(field: any, errors: string[] = [], category: string) {
        const dictionary = this.errors.get(field) ?? {};

        dictionary[category] = errors;
        this.errors.set(field, dictionary);
    }

    /** Remove all errors of a specific field */
    removeErrors(field: any, category?: string) {
        const dictionary = this.errors.get(field);

        if (dictionary == null) {
            return;
        }

        if (category) {
            delete dictionary[category];
        } else {
            this.errors.delete(field);
        }

    }

    /** Retrieve the list of errors of a specific field */
    getErrors(field: any, category?: string): string[] {

        if (field != null) {
            // Single entry
            const dictionary = this.errors.get(field);

            if (!dictionary) {
                return [];
            }

            if (category) {
                return dictionary[category] ?? [];
            }

            const res: string[] = [];

            Object.keys(dictionary).map((key) => dictionary[key]).forEach((ers) => res.push(...ers ?? []));

            return res;
        }

        // Whole builder
        const all: string[] = [];

        this.errors.forEach((dictionary) => Object.keys(dictionary).map((key) => dictionary[key]).forEach((ers) => all.push(...ers ?? [])));

        return all;
    }

    /** Check the validity status of a specific field */
    isValid(field?: any): boolean {
        return !this.getErrors(field).length;
    }

    /** Check if a specific field is new */
    isNew(field?: any): boolean {
        if (field) {
            return field.isNew != null;
        }

        return !this.definition.lastModifiedAt;
    }

    markFieldsSubmitted(source?: any[]): void {
        if (!this.definition.fields?.length) {
            return;
        }

        if (!source) {
            source = this.definition.fields as any[];
        }

        for (const field of source) {
            field.isSubmitted = true;

            if (field.fields?.length) {
                this.markFieldsSubmitted(field.fields);
            }
        }
    }

    /**
     * remove empty values and extra data
     * required by builder before saving
     */
    cleanPage(consolePage: UcPage | any): UcPage {
        const assets: string[] = [];
        const DefinitionRequiredParams = ['fields'];
        const FieldRequiredParams = ['value', 'options', 'validators', 'visibleFields'];
        const FieldAttributesToRemove = ['isNew'];

        // Create new reference to ensure consoleDefinition remains unedited
        const page = JSON.parse(JSON.stringify(consolePage));

        this.cleanObject(page, DefinitionRequiredParams);

        for (const field of this.fieldIterable(page.fields || [])) {
            this.cleanObject(field, FieldRequiredParams, FieldAttributesToRemove);

            if (field.type === FieldType.MultiText) {
                const linked = this.getLinkedImagesAndVideos((field as PageField).value);

                if (linked.length) {
                    assets.push(...linked);
                }
            }

        }

        page._assetsRef = [...new Set(assets)];

        return page;
    }

    /**
     * remove empty values and extra data
     * required by builder before saving
     */
    cleanDefinition(consoleDefinition: UcDefinition): UcDefinition {

        this.cleanDefinitionStart.next(consoleDefinition);

        const DefinitionRequiredParams = ['fields'];
        const FieldRequiredParams = ['options', 'validators', 'transitions'];
        const FieldAttributesToRemove = ['isNew'];

        // Update referenced Repeating groups
        this.updateRepeatFields();

        // Create new reference to ensure consoleDefinition remains unedited
        const definition = JSON.parse(JSON.stringify(consoleDefinition)) as UcDefinition;

        this.cleanObject(definition, DefinitionRequiredParams);

        for (const field of this.fieldIterable(definition.fields ?? [])) {

            this.cleanObject(field, FieldRequiredParams, FieldAttributesToRemove);

            if (definition.compoundType === CompoundType.Form) {
                (definition as any).assetsRef = [...new Set(((definition as any) || []).concat(this.getLinkedAssets(field)))];
            }
        }

        this.cleanUpFields(definition.fields);

        return definition;
    }

    getAssetsInView(view: UcView) {
        const assets: string[] = [];

        for (const field of this.fieldIterable(view._definition.fields ?? [])) {
            if (field.type === FieldType.MultiText && field.identifier) {
                const linked = this.getLinkedImagesAndVideos(view[field.identifier]);

                if (linked.length) {
                    assets.push(...linked);
                }
            }
        }

        return [...new Set(assets)];
    }

    refreshTags(field?: any) {
        if (!field) {
            // first call, reset loaded tags
            this.tags = [];
            field = this.definition;
            if (!field) {
                // structure builder doesn't have a compound
                return;
            }
        }
        if (field.tags) {
            (field.tags as string[]).forEach((tag) => {
                if (!this.tags.includes(tag)) {
                    this.tags.push(tag);
                }
            });
        }
        if (field[this.childrenProperty]) {
            (field[this.childrenProperty] as any[]).forEach((c) => { this.refreshTags(c); });
        }
    }

    refreshStatuses(field?: any) {

        if (!field) {
            // first call, reset loaded statuses
            this.statuses.clear();
            field = this.definition;
            if (!field) {
                // structure builder doesn't have a compound
                return;
            }
        }

        if (field.transitions) {
            (field.transitions).forEach((tr: any) => {
                this.statuses.add(tr.source);
                this.statuses.add(tr.target);
            });
        }

        if (field[this.childrenProperty]) {
            (field[this.childrenProperty] as any[]).forEach((c) => { this.refreshStatuses(c); });
        }
    }

    cleanUpFields(fields: Field[] | UcField[] = [], parent: Field | UcField | null = null) {

        for (const field of fields) {

            const untypedField = field as any;

            delete untypedField.isNew;

            if (untypedField.isSubmitted) {
                delete untypedField.isSubmitted;
            }

            if (ValidatorFunctions.isEmpty(untypedField.name)) {
                delete untypedField.name;
            }

            if (ValidatorFunctions.isEmpty(untypedField.condition)) {
                delete untypedField.condition;
            }

            if (ValidatorFunctions.isEmpty(untypedField.dataSource)) {
                delete untypedField.dataSource;
            }

            if (ValidatorFunctions.isEmpty(field.format)) {
                delete field.format;
            }

            if (ValidatorFunctions.isEmpty(field.autofill)) {
                delete field.autofill;
            }

            if (ValidatorFunctions.isEmpty(field.identifier)) {
                delete field.identifier;
            }

            if (ValidatorFunctions.isEmpty(field.help)) {
                delete field.help;
            }

            if (ValidatorFunctions.isEmpty(field.label)) {
                delete field.label;
            }

            if (parent?.type === FieldType.Survey) {
                delete field.template;
                delete field.width;
                delete field.breakAfter;
                delete field.placeholder;
            }

            if (field.fields?.length) {
                this.cleanUpFields(field.fields, field);
            }
        }
    }

    /** Remove from the content any entry that is not defined in the fields list */
    filterDefinedFields(fields: Field[], content: Dictionary<any>): Dictionary<any> {
        const result: Dictionary<any> = {};

        fields.forEach((f) => {
            if (content[f.identifier as string]) {
                result[f.identifier as string] = content[f.identifier as string];
            }
        });

        return result;
    }

    getControlErrors(groupControl: UntypedFormGroup): string[] {

        const errors: string[] = [];

        for (const control of this.controlIterable(groupControl.controls)) {

            if (control.errors?.message) {
                const { message } = control.errors;

                errors.push(message);
            }
        }

        return [...new Set(errors)];
    }

    *fieldIterable<T extends Field | PageField | UcField>(fields: T[]): Iterable<T> {

        for (const field of fields) {

            if (field.fields) {
                yield *this.fieldIterable(field.fields as T[]);
            }
            yield field;
        }
    }

    private cleanObject(obj: Dictionary<any>, requiredParams: string[] = [], paramsToRemove: string[] = []) {

        // Removed any params considered to be empty unless they are required
        for (const key of Object.keys(obj)) {

            if (paramsToRemove.includes(key) ||
                (ValidatorFunctions.isEmpty(obj[key]) && !Object.keys(requiredParams).includes(key))) {
                delete obj[key];
            }
        }
    }

    private getLinkedAssets(field: Field | UcField): string[] {

        const help = this.getLinkedImagesAndVideos(field.help);
        const options = (field.options ?? []).reduce<string[]>((result, option) => [...result, ...this.getLinkedImagesAndVideos(option.content)], []);

        return [...help, ...options];
    }

    private getLinkedImagesAndVideos(value = ''): string[] {
        const types = ['unifii-image', 'unifii-video'];
        const assets: string[] = [];

        const markdownParsed = this.markdown.parse(value, {});

        for (const item of this.markdownTokenIterator(markdownParsed)) {
            if (types.includes(item.type) && item.meta?.id) {
                assets.push(item.meta.id as string);
            }
        }

        return assets;
    }

    private *markdownTokenIterator(markdownData: any[]): IterableIterator<any> {
        for (const item of markdownData) {
            if (item.children) {
                yield *this.markdownTokenIterator(item.children);
            }
            yield item;

        }
    }

    private *controlIterable(controls: AbstractControl[] | Record<string, AbstractControl>): Iterable<AbstractControl> {

        if (Array.isArray(controls)) {
            for (const control of controls) {

                yield control;

                if ((control as UntypedFormGroup | UntypedFormArray).controls) {
                    yield *this.controlIterable((control as UntypedFormGroup | UntypedFormArray).controls);
                }
            }
        } else {

            for (const key of Object.keys(controls)) {
                const control = controls[key];

                if (!control) {
                    continue;
                }

                yield control;

                if ((control as UntypedFormGroup | UntypedFormArray).controls) {
                    yield *this.controlIterable((control as UntypedFormGroup | UntypedFormArray).controls);
                }
            }
        }
    }

    private updateRepeatFields() {

        if (this.horizontalTableConfig == null) {
            return;
        }

        this.horizontalTableConfig.forEach(({ hideFromColumnsOnMobile, hideFromColumnsOnDesktop }, field) => {

            field.templateConfig = {
                hideFromColumnsOnMobile: hideFromColumnsOnMobile.filter((f) => !!f.identifier).map((f) => f.identifier) as string[],
                hideFromColumnsOnDesktop: hideFromColumnsOnDesktop.filter((f) => !!f.identifier).map((f) => f.identifier) as string[],
            };
        });
    }

}
