import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FeatureFlagService, ModalService, ToastService, UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Language, ensureError, hasLengthAtLeast } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { AppInfo, Media, UcApps, UcClient, UcFormBucketClient, UcProject } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { EditData } from 'components/common/edit-data';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';
import { ContextService } from 'services/context.service';
import { translatedLanguages } from 'services/translate-loader';

import { SettingsComponent } from '../settings.component';

enum SettingsControlKeys {
    CompanyBucket = 'CompanyBucket',
    Languages = 'language',
    LiveApp = 'liveApp',
    LiveWebUrl = 'liveWebUrl',
    Logo = 'logo',
    Name = 'name',
    Offline = 'offline',
    PreviewApp = 'previewApp',
    PreviewWebUrl = 'previewWebUrl',
    PrivacyPolicyUrl = 'privacyPolicyUrl',
    PrimaryLanguage = 'primaryLanguage',
}

@Component({
    selector: 'uc-settings-general',
    templateUrl: './settings-general.html',
    styleUrls: ['./settings-general.less'],
})
export class SettingsGeneralComponent implements EditData, OnInit, OnDestroy {

    protected readonly settingsControlKeys = SettingsControlKeys;
    protected form: UfControlGroup;
    protected langResults: Language[] = [];
    protected companyResults: string[] = [];
    protected allApps: AppInfo[];
    protected showCompanyProfile: boolean;

    private allLanguages: Language[] = [];
    private subscriptions = new Subscription();

    private featureFlagService = inject(FeatureFlagService);
    private toastService = inject(ToastService);
    private context = inject(ContextService);
    private modalService = inject(ModalService);
    private ucProject = inject(UcProject);
    private ucFormBucketClient = inject(UcFormBucketClient);
    private ucClient = inject(UcClient);
    private ucApps = inject(UcApps);
    private parent = inject(SettingsComponent);
    private ufb = inject(UfFormBuilder);
    private builderHeaderService = inject(BuilderHeaderService);

    get edited(): boolean {
        return !!this.builderHeaderService.config.edited;
    }

    private get languagesControl() {
        return this.form.get(SettingsControlKeys.Languages) as UfControl;
    }

    private get logoControl() {
        return this.form.get(SettingsControlKeys.Logo) as UfControl;
    }

    async ngOnInit() {

        if (!this.context.project) {
            throw new Error('Project required');
        }

        this.form = this.ufb.group({
            [SettingsControlKeys.CompanyBucket]: [this.context.project.companyBucket],
            [SettingsControlKeys.Languages]: [this.context.project.languages],
            [SettingsControlKeys.LiveApp]: [this.context.project.liveApp],
            [SettingsControlKeys.LiveWebUrl]: [this.context.project.liveWebUrl],
            [SettingsControlKeys.Logo]: [this.context.project.logo],
            [SettingsControlKeys.Name]: [this.context.project.name, ValidatorFunctions.required('Name is required')],
            [SettingsControlKeys.Offline]: [this.context.project.offline],
            [SettingsControlKeys.PreviewApp]: [this.context.project.previewApp],
            [SettingsControlKeys.PreviewWebUrl]: [this.context.project.previewWebUrl],
            [SettingsControlKeys.PrimaryLanguage]: [this.context.project.primaryLanguage, ValidatorFunctions.required('Primary language is required')],
            [SettingsControlKeys.PrivacyPolicyUrl]: [this.context.project.privacyPolicyUrl],
        });

        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            this.parent.edited = true;
        }));

        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(() => { void this.save(); }));

        this.builderHeaderService.buildConfig({
            cancelRoute: ['../'],
            breadcrumbs: this.parent.breadcrumbs,
            lastModifiedAt: this.context.project.lastModifiedAt,
            lastModifiedBy: this.context.project.lastModifiedBy,
        });

        this.showCompanyProfile = await this.featureFlagService.isEnabled('companies');
        this.allApps = await this.ucApps.get();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.parent.buildHeaderConfig();
    }

    protected async findLogo() {
        const result: Media[] | undefined = await this.modalService.openMedium(MediaSearchComponent, {
            title: 'Find Logo',
            type: 'Image',
        });

        if (!result || !hasLengthAtLeast(result, 1)) {
            return;
        }

        this.logoControl.setValue({
            id: result[0].id as number,
            url: result[0].url,
        });
    }

    protected deleteLogo() {
        this.logoControl.reset();
    }

    protected async searchLang(query?: string, excludePrimary?: boolean) {

        // get all available languages
        if (!this.allLanguages.length) {
            this.allLanguages = await this.ucClient.getLanguages();
        }

        if (excludePrimary) {
            const primaryLanguage = this.form.get(SettingsControlKeys.PrimaryLanguage)?.value;

            this.langResults = this.langResults.filter((l) => !primaryLanguage || primaryLanguage.code !== l.code);
        } else {
            const availableLanguages = this.allLanguages.filter((language) => translatedLanguages.includes(language.code));
            const languages = this.languagesControl.value as Language[];

            this.langResults = availableLanguages
            .filter((availableLanguage) => !languages
                .find((projectLanguage) => projectLanguage.code === availableLanguage.code),
            );
        }

        if (!query?.trim().length) {
            return;
        }
        const valorizedQuery = query;

        query = query.toLowerCase();

        this.langResults = this.langResults.filter((l) =>
            l.code.toLowerCase().includes(valorizedQuery) || l.name.toLowerCase().includes(valorizedQuery),
        );
    }

    protected async searchBucket(query: string) {
        this.companyResults = (await this.ucFormBucketClient.list({ params: { q: query, hasRollingVersion: true } })).map((b) => b.id);
    }

    protected addLang(entry?: Language) {
        if (!entry) {
            return;
        }

        const languages = this.languagesControl.value as Language[];

        this.languagesControl.setValue([...languages, entry]);
    }

    protected removeLang(i: number) {
        const languages = this.languagesControl.value as Language[];

        languages.splice(i, 1);

        this.languagesControl.setValue(languages);
    }

    private async save() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        try {
            const project = await this.ucProject.save(Object.assign({}, this.context.project, this.form.value));

            this.context.project = project;
            this.toastService.success('Changes saved!');
            this.parent.edited = false;

            this.builderHeaderService.updateConfig({
                lastModifiedAt: project.lastModifiedAt,
                lastModifiedBy: project.lastModifiedBy,
            });
        } catch (e) {
            this.toastService.error(ensureError(e, 'Failed to save project').message);
        }

    }

}
