"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrantType = exports.MfaChallengeType = void 0;
var MfaChallengeType;
(function (MfaChallengeType) {
    MfaChallengeType["Virtual"] = "virtual";
    MfaChallengeType["RecoveryCode"] = "recovery_code";
    MfaChallengeType["Sms"] = "sms";
})(MfaChallengeType || (exports.MfaChallengeType = MfaChallengeType = {}));
var GrantType;
(function (GrantType) {
    GrantType["RefreshToken"] = "refresh_token";
    GrantType["AuthorizationCode"] = "authorization_code";
    GrantType["MfaVirtual"] = "mfa_virtual";
    GrantType["MfaRecoveryCode"] = "mfa_recovery_code";
    GrantType["Password"] = "password";
    GrantType["ResetPassword"] = "reset_password";
    GrantType["MfaSms"] = "mfa_sms";
})(GrantType || (exports.GrantType = GrantType = {}));
