import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ClipboardService, DataDisplayListItem, DataDisplayService, ModalService, ToastService } from '@unifii/library/common';
import { UfError, isStringNotEmpty } from '@unifii/sdk';

import { ApiKey, UcAPIKeys, UcPermissionsClient } from 'client';
import { useDefaultErrorMessage } from 'components';
import { ConsoleNameLabel } from 'constant';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { TitleService } from 'services/title.service';

import { APIKeysTableManager } from './api-keys-table-manager';
import { APIKeysComponent } from './api-keys.component';

@Component({
    templateUrl: './api-key-details.html',
    styleUrls: ['./api-key-details.less'],
    providers: [{
        provide: UcPermissionsClient,
        useFactory: (service: UcAPIKeys, route: ActivatedRoute) => service.permissions(route.snapshot.params.id),
        deps: [UcAPIKeys, ActivatedRoute],
    }],
})
export class APIKeyDetailsComponent implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') stretchClass = true;

    protected item: ApiKey;
    protected breadcrumbs: Breadcrumb[] = [];
    protected error?: UfError;
    protected clipboard = inject(ClipboardService);
    protected items: DataDisplayListItem[] = [];

    private ucAPIKeys = inject(UcAPIKeys);
    private toast = inject(ToastService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private parent = inject(APIKeysComponent);
    private modalService = inject(ModalService);
    private breadcrumbService = inject(BreadcrumbService);
    private tableManager = inject<APIKeysTableManager>(TableContainerManager);
    private titleService = inject(TitleService);
    private dataDisplayService = inject(DataDisplayService);

    ngOnInit() {
        void this.init();
    }

    ngOnDestroy() {
        this.parent.newApiKey = null;
    }

    protected async revoke() {
        if (!this.item.key) {
            return;
        }

        if (!await this.modalService.openConfirm({
            title: `Confirm Revoke`,
            message: `Revoke ${this.item.consoleName}?`,
        })) {
            return;
        }

        await this.ucAPIKeys.delete(this.item.key);
        this.toast.success(`API Key ${this.item.consoleName} revoked`);
        this.tableManager.reload.next();
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    private async init() {
        if (this.parent.newApiKey) {
            this.item = this.parent.newApiKey;
        } else {
            try {
                this.item = await this.ucAPIKeys.getDetails(this.route.snapshot.params.id);
                delete this.item.secret; // TODO Remove once API is fixed
            } catch (e) {
                this.error = useDefaultErrorMessage(e);

                return;
            }
        }

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.item.consoleName]);

        this.items = [
            { term: ConsoleNameLabel, data: this.item.consoleName },
            { term: 'Key', data: this.item.key },
        ];

        if (this.item.lastUsedAt) {
            this.items.push(
                { term: 'Last Used At', data: this.dataDisplayService.displayAsString(this.item.lastUsedAt) },
            );
        }

        if (isStringNotEmpty(this.item.consoleName)) {
            this.titleService.updateTitle(this.item.consoleName);
        }
    }

}
