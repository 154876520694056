"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
const client_headers_1 = require("./client-headers");
const constants_1 = require("./constants");
const functions_1 = require("./functions");
const oauth_request_wrapper_1 = require("./oauth-request-wrapper");
const request_manager_1 = require("./request-manager");
// TODO add once node environment established
// uncertain on the correct variable names for nfetch
// if (!(global as any).fetch) {
//     (global as any).fetch = (global as any).nfetch;
// }
// if (!(global as any).Headers) {
//     (global as any).Headers = (global as any).nodeFetch.Headers;
// }
// TODO Move authentication & status to AuthenticationClient separate class
class Client {
    constructor(options, storage, interceptor) {
        this.options = options;
        this.storage = storage;
        /**
         * Token Storage values
         * Local values provide a fallback if oauth is used without token storage provided
         */
        this._token = null;
        this._limitedToken = null;
        this._expiresAt = null;
        this._refreshToken = null;
        this.headers = new client_headers_1.ClientHeaders(this.options);
        this.authRequest = new oauth_request_wrapper_1.OAuthRequestWrapper(this.options, this.headers, this);
        this.request = new request_manager_1.RequestManager(this.options, this.headers, this, this.authRequest, interceptor);
    }
    set token(v) {
        if (this.storage) {
            this.storage.token = v;
        }
        else {
            this._token = v;
        }
    }
    get token() {
        if (this.storage) {
            return this.storage.token;
        }
        return this._token;
    }
    set limitedToken(v) {
        if (this.storage) {
            this.storage.limitedToken = v;
        }
        else {
            this._limitedToken = v;
        }
    }
    /* temporary limited access token for MFA verification purposes  */
    get limitedToken() {
        if (this.storage) {
            return this.storage.limitedToken;
        }
        return this._limitedToken;
    }
    set expiresAt(v) {
        if (this.storage) {
            this.storage.expiresAt = v;
        }
        else {
            this._expiresAt = v;
        }
    }
    get expiresAt() {
        if (this.storage) {
            return this.storage.expiresAt;
        }
        return this._expiresAt;
    }
    authenticateWithPassword(params) {
        this.requestStart();
        return this.authRequest.authWithPassword(params).finally(() => this.requestComplete());
    }
    authenticateWithCode(params) {
        this.requestStart();
        return this.authRequest.authWithCode(params).finally(() => this.requestComplete());
    }
    authenticateWithVirtualMfa(params) {
        this.requestStart();
        return this.authRequest.authWithVirtualMfa(params).finally(() => this.requestComplete());
    }
    authenticateWithMfaRecoveryCode(params) {
        this.requestStart();
        return this.authRequest.authWithMfaRecoveryCode(params).finally(() => this.requestComplete());
    }
    authenticateWithMfaSms(params) {
        this.requestStart();
        return this.authRequest.authWithMfaSms(params).finally(() => this.requestComplete());
    }
    authenticateWithResetToken(params) {
        this.requestStart();
        return this.authRequest.authWithResetPasswordToken(params).finally(() => this.requestComplete());
    }
    async registerDevice(device) {
        await this.put(this.buildUrl(constants_1.APIPath.Devices, device.id), device, { anonymous: true });
        this.headers.deviceId = device.id;
    }
    getDownloadToken(url) {
        return this.post(this.buildUrl(constants_1.APIPath.DownloadTokens), { body: { url } });
    }
    buildUrl(...parts) {
        parts = parts.map((p) => encodeURIComponent(p));
        parts.unshift('v0');
        parts.unshift(this.options.baseUrl);
        return parts.join('/');
    }
    /**
     * url: string, params?: Record<string, unknown>, query?: string, headers?: Headers, signal?: AbortSignal, analytics?: RequestAnalytics, anonymous?: boolean
     */
    get(url, options) {
        return this.send('GET', url, options);
    }
    /**
     * url: string, body: any, params?: Record<string, unknown>, signal?: AbortSignal, analytics?: RequestAnalytics, anonymous?: boolean
     */
    put(url, body, options) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        return this.send('PUT', url, { body, ...options });
    }
    /**
     * url: string, body?: any, params?: Record<string, unknown>, signal?: AbortSignal, analytics?: RequestAnalytics, multipart?: boolean, anonymous?: boolean
     */
    post(url, options) {
        return this.send('POST', url, options);
    }
    /**
     * url: string, body: any, params?: Record<string, unknown>, signal?: AbortSignal, analytics?: RequestAnalytics, anonymous?: boolean
     */
    patch(url, body, options) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        return this.send('PATCH', url, { body, ...options });
    }
    /**
     * url: string, signal?: AbortSignal, analytics?: RequestAnalytics, anonymous?: boolean
     */
    delete(url, options) {
        return this.send('DELETE', url, { ...options });
    }
    head(url, options) {
        return this.send('HEAD', url, { ...options });
    }
    /**
     * file: File, url: string, id?: string, progressCallback?: (progress: Progress) => void, signal?: AbortSignal
     */
    upload(file, url, options) {
        return this.request.upload({ file, url, ...options });
    }
    async setRefreshToken(v) {
        if (this.storage) {
            await this.storage.setRefreshToken(v);
        }
        else {
            this._refreshToken = v;
        }
    }
    async getRefreshToken() {
        if (this.storage) {
            try {
                return await this.storage.getRefreshToken();
            }
            catch (e) {
                return null;
            }
        }
        return this._refreshToken;
    }
    async send(method, url, options) {
        this.requestStart(options?.skipExecutionEmit);
        url = (0, functions_1.appendParamsToUrl)(url, options?.params, options?.query);
        try {
            return await this.request.send({ method, url, ...options });
        }
        finally {
            this.requestComplete(options?.skipExecutionEmit);
        }
    }
    requestStart(skipExecutionEmit = false) {
        if (this.start && !skipExecutionEmit) {
            this.start();
        }
    }
    requestComplete(skipExecutionEmit = false) {
        if (this.end && !skipExecutionEmit) {
            this.end();
        }
    }
}
exports.Client = Client;
