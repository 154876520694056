import { Component, Inject, OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb } from '@unifii/library/common';
import { isStringNotEmpty } from '@unifii/sdk';

import { App, UcApps } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { TenantSettingsComponent } from 'pages/system-settings/tenant-settings.component';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { TitleService } from 'services/title.service';

import { AppsTableManager } from './apps-table-manager';

@Component({
    selector: 'uc-app-details',
    templateUrl: './app-details.html',
})
export class AppDetailsComponent extends TabsPage implements OnInit, OnDestroy {

    override edited: boolean;
    name: string;
    breadcrumbs: Breadcrumb[];
    templateRef?: TemplateRef<any>;

    protected readonly arePushNotificationsEnabled = inject(ContextService).tenantSettings?.arePushNotificationsEnabled ?? false;

    private _id: string;

    constructor(
        private ucApps: UcApps,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        private parent: TenantSettingsComponent,
        private titleService: TitleService,
        @Inject(TableContainerManager) private manager: AppsTableManager,
    ) {
        super();
        this.subscriptions.add(this.route.params.subscribe((params) => { this.id = params.id; }));
        this.subscriptions.add(this.ucApps.appModified.subscribe((app) => { this.updateName(app); }));
        this.subscriptions.add(this.ucApps.appAdded.subscribe((app) => { this.reload(app.id); }));
    }

    override ngOnInit() {
        this.buildHeaderConfig();
        super.ngOnInit();
    }

    override ngOnDestroy() {
        this.parent.edited = false;
        super.ngOnDestroy();
    }

    get isNew() {
        return this.id === 'new';
    }

    set id(v: string) {

        if (v === this._id) {
            return;
        }

        this._id = (null as any as string);
        this._id = v;

        if (this.isNew) {
            this.name = 'New';

            return;
        }

        this.updateName();
    }

    get id(): string {
        return this._id;
    }

    /**
     * @description
     * This method is public because it will be called by children components
     * when they're destroyed, so we can update the header
     */
    buildHeaderConfig() {
        const name = this.isNew ? 'New' : this.name;

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [name]);

        this.builderHeaderService.init();
        this.builderHeaderService.buildConfig({
            breadcrumbs: this.breadcrumbs,
            hideSaveButton: true,
        });
        this.templateRef = undefined;
        if (isStringNotEmpty(this.name)) {
            this.titleService.updateTitle(`Apps | ${this.name}`, true);
        }
    }

    private updateName(app?: App) {
        if (app) {
            this.name = app.name;
            this.manager.updateItem.next(app);
        }

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.name]);
        this.buildHeaderConfig();
    }

    private reload(id: string) {
        this.manager.reload.next();
        void this.router.navigate(['../', id], { relativeTo: this.route });
    }

}
