"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthRequestWrapper = void 0;
const date_fns_1 = require("date-fns");
const client_models_1 = require("./client-models");
const client_types_1 = require("./client-types");
const constants_1 = require("./constants");
const functions_1 = require("./functions");
const models_1 = require("./models");
/**
 * Simple wrapper for Auth requests, responsible for
 * - Creating fetch
 * - Saving auth information into storage
 * - Removing expired information
 */
class OAuthRequestWrapper {
    constructor(options, headers, storage) {
        this.options = options;
        this.headers = headers;
        this.storage = storage;
    }
    /** Authenticate with Username and Password
     *  Usage: Login Page
     */
    authWithPassword(params) {
        return this.request(models_1.GrantType.Password, params);
    }
    /** Authenticate with Code
     *  Usage: Password Email Reset
     */
    authWithCode(params) {
        return this.request(models_1.GrantType.AuthorizationCode, params);
    }
    /** Authenticate Virtual MFA
     *  Requires Full access or limited access token to be exchanged for a new full access token with timestamp
     *  Usage: MFA Verification Page
     */
    authWithVirtualMfa(params) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const access_token = this.storage.token ?? this.storage.limitedToken;
        if (!access_token) {
            throw Error('No Access Token');
        }
        return this.request(models_1.GrantType.MfaVirtual, { mfa_token: params.mfa_token, access_token });
    }
    /** Authenticate MFA Recovery Code
     *  Requires Full access or limited access token to be exchanged for a new full access token with timestamp
     *  Usage: MFA Verification Page
     */
    authWithMfaRecoveryCode(params) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const access_token = this.storage.token ?? this.storage.limitedToken;
        if (!access_token) {
            throw Error('No Access Token');
        }
        return this.request(models_1.GrantType.MfaRecoveryCode, { recovery_code: params.recovery_code, access_token });
    }
    /** Authenticate MFA SMS
     *  Requires Full access or limited access token to be exchanged for a new full access token with timestamp
     *  Usage: MFA Verification Page
     */
    authWithMfaSms(params) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const access_token = this.storage.token ?? this.storage.limitedToken;
        if (!access_token) {
            throw Error('No Access Token');
        }
        return this.request(models_1.GrantType.MfaSms, { code: params.code, challenge: params.challenge, access_token });
    }
    /** Authenticate Refresh token
     *  Exchange Refresh token for a new full access token
     *  Usage: Preflight network request
     */
    authWithRefreshToken(params) {
        return this.request(models_1.GrantType.RefreshToken, params);
    }
    /** Authenticate Reset Password
     *  Exchange Single Use token for a limited access token
     *  Usage: Reset Password Email
     */
    authWithResetPasswordToken(params) {
        return this.request(models_1.GrantType.ResetPassword, params);
    }
    async request(grantType, params) {
        const body = this.getRequestBody({ grant_type: grantType, ...params });
        const headers = this.headers.base;
        headers.set(client_types_1.HeaderKeys.ContentType, client_models_1.ClientContentType.ApplicationXWwwFormUrlencoded);
        const response = await fetch(this.options.baseUrl + `/${constants_1.APIPath.Oauth}`, {
            method: 'POST',
            headers,
            body,
        });
        if (response.ok) {
            const authResponse = await response.json();
            await this.updateStorage(authResponse);
            return authResponse;
        }
        // Auth Error
        this.storage.expiresAt = null;
        this.storage.token = null;
        await this.storage.setRefreshToken(null);
        throw await (0, functions_1.getError)(response);
    }
    getRequestBody(params) {
        const urlSearchParams = this.urlSearchParams;
        for (const key of Object.keys(params)) {
            const value = params[key];
            if (value) {
                urlSearchParams.set(key, value);
            }
        }
        return urlSearchParams.toString();
    }
    get urlSearchParams() {
        const urlSearchParams = new URLSearchParams();
        if (this.options.appId) {
            urlSearchParams.set(constants_1.QueryParams.ClientId, this.options.appId);
        }
        return urlSearchParams;
    }
    async updateStorage(resp) {
        // store mfa challenge limitedToken 
        if (resp.access_token && resp.mfa_challenge) {
            this.storage.limitedToken = resp.access_token;
            return;
        }
        // reset limitedToken
        this.storage.limitedToken = null;
        this.storage.token = resp.access_token;
        // subtract a 5 second buffer from expiry for robustness
        const expiresAt = (0, date_fns_1.formatRFC3339)((0, date_fns_1.addSeconds)(new Date(), resp.expires_in - 5));
        this.storage.expiresAt = expiresAt;
        await this.storage.setRefreshToken(resp.refresh_token);
    }
}
exports.OAuthRequestWrapper = OAuthRequestWrapper;
