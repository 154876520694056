import { ChangeDetectorRef, Component, Input, NgZone, OnInit, inject } from '@angular/core';
import { CommonTranslationKey, ModalService, UfControl, UfControlGroup } from '@unifii/library/common';
import { OAuthWithVirtualMfa, TenantClient } from '@unifii/sdk';
import { UserKeys, UserMfaInfoKeys, UserSetupRecoveryCodesModalComponent, UserSetupSmsModalComponent, UserSetupVirtualMfaModalComponent } from '@unifii/user-provisioning';

import { UcClient } from 'client';
import { AuthenticationService } from 'services/authentication.service';
import { ContextService } from 'services/context.service';

@Component({
    selector: 'uc-user-mfa',
    templateUrl: 'user-mfa.html',
})
export class UserMfaComponent implements OnInit {

    @Input({ required: true }) form: UfControlGroup;
    @Input() selfSetup = false;
    @Input() cssClasses: string | string[] | undefined | null;

    protected readonly commonTK = CommonTranslationKey;
    protected readonly userMfaInfoKeys = UserMfaInfoKeys;
    protected isMfaOptionalControl?: UfControl;
    protected mfaControl?: UfControlGroup;

    private cdr = inject(ChangeDetectorRef);
    private zone = inject(NgZone);
    private modalService = inject(ModalService);
    private context = inject(ContextService);
    private client = inject(UcClient);
    private tenantClient = inject(TenantClient);
    private authService = inject(AuthenticationService);

    ngOnInit() {
        this.isMfaOptionalControl = this.form.get(UserKeys.IsMfaOptional) as UfControl | undefined;
        this.mfaControl = this.form.get(UserKeys.Mfa) as UfControlGroup | undefined;
    }

    protected async setupVirtualMfa(): Promise<void> {
        await this.zone.run(async() => {

            const tenantName = (await this.tenantClient.getSettings()).name;

            if (!this.context.account?.username) {
                throw new Error('No username');
            }

            const label = `(${tenantName}) ${this.context.account.username}`;

            const result = await this.modalService.openMedium(UserSetupVirtualMfaModalComponent, { label });

            if (!result) {
                return;
            }

            const response = await this.client.setVirtualMfaCode(result.secret);

            await this.authService.login({ mfa_token: result.token } satisfies OAuthWithVirtualMfa);

            (this.mfaControl?.get(UserMfaInfoKeys.VirtualCode) as UfControl | undefined)?.setValue(response.secret);
        });
	}

	protected async setupRecoverCodes(): Promise<void> {
        this.refresh();
		const recoveryCodes = await this.modalService.openMedium(UserSetupRecoveryCodesModalComponent);

        if (!recoveryCodes) {
            return;
        }

        await this.client.setRecoveryCodes(recoveryCodes);

        (this.mfaControl?.get(UserMfaInfoKeys.HasRecoveryCodes) as UfControl | undefined)?.setValue(true);
	}

    protected async setupSms(): Promise<void> {
        this.refresh();
        const oAuthWithMfaSms = await this.modalService.openMedium(UserSetupSmsModalComponent, { issueSmsChallenge: () => this.client.issueSmsChallenge() });

        if (!oAuthWithMfaSms) {
            return;
        }

        await this.authService.login(oAuthWithMfaSms);
        await this.client.setSmsMfaEnabled();

        (this.mfaControl?.get(UserMfaInfoKeys.IsSmsEnabled) as UfControl | undefined)?.setValue(true);
	}

    // value change occurs after async call
    protected refresh() {
        this.cdr.detectChanges();
    }

}
