import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { Breadcrumb, TabsComponent } from '@unifii/library/common';
import { UfError, isUfError } from '@unifii/sdk';

import { FormBucketService } from 'pages/form-data/bucket-service';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { OnGrandChildNavigationEndService } from 'services/on-grandchild-navigation-end.service';
import { TitleService } from 'services/title.service';

import { bucketConfigResolver } from './bucket-config-resolver';

/** Route non-reusable component, see ConsoleRouteReuseStrategy */
@Component({
    selector: 'uc-bucket',
    templateUrl: 'bucket.html',
    providers: [FormBucketService, OnGrandChildNavigationEndService],
})
export class BucketComponent extends TabsPage implements OnInit, OnDestroy, AfterViewChecked {

    @ViewChild(TabsComponent, { static: false }) private tabsComponent: TabsComponent | undefined;
    override edited: boolean;

    protected error?: UfError;
    protected breadcrumbs: Breadcrumb[];
    protected context = inject(ContextService);

    private bucketService = inject(FormBucketService);
    private breadcrumbService = inject(BreadcrumbService);
    private titleService = inject(TitleService);
    private grandChildrenNavigationService = inject(OnGrandChildNavigationEndService);

    override ngOnInit() {
        const resolverData = this.route.snapshot.data.bucketConfig as Awaited<ReturnType<typeof bucketConfigResolver>>;

        if (isUfError(resolverData)) {
            this.error = resolverData;

            return;
        }

        const { schema, schemaSetting, dataDescriptor, publishedSchema } = resolverData;

        this.bucketService.schema = schema;
        this.bucketService.publishedSchema = publishedSchema;
        this.bucketService.schemaSetting = schemaSetting;
        this.bucketService.dataDescriptor = dataDescriptor;
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [schema.bucket]);

        super.ngOnInit();
    }

    override ngOnDestroy() {
        this.context.bucketId = undefined;
        super.ngOnDestroy();
    }

    ngAfterViewChecked() {
        this.grandChildrenNavigationService.register(this.updateTitle.bind(this));
    }

    protected updateTitle() {
        const currentTab = this.tabsComponent?.selected;

        if (currentTab) {
            this.titleService.updateTitle(`${this.bucketService.schema.bucket} | ${currentTab.label}`, true);
        }
    }

}
